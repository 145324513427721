import { TFunction } from 'react-i18next';
import { ListTypeEnum } from 'src/graphql-types/globalRankingTypes';

const getListType = (listType: ListTypeEnum | null, t: TFunction) => {
  switch (listType) {
    case ListTypeEnum.STANDING:
      return t('standing');
    case ListTypeEnum.SEEDING:
      return t('seeding');
    case ListTypeEnum.BONUS_POINTS:
      return t('bonus points');
    case ListTypeEnum.QUOTA:
      return t('quota');
    case ListTypeEnum.YEAR_END:
      return t('year end');
    case ListTypeEnum.L2_QUALIFIER:
      return t('level 2 qualifier');
    case ListTypeEnum.DIRECT_ACCEPTANCE:
      return t('direct acceptance');
    default:
      return t('n/a');
  }
};

export default getListType;
