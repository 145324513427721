import CronTime from 'cron-time-generator';
import {
  dayOfTheMonthOptions,
  DayOfTheWeekEnum,
  FrequencyOption,
  timeOptions,
} from 'src/components/rankings/ranking-runs/edit-scheduled-runs-dialog';

export const getDayDifference = (curr, next) => {
  if (next > curr) {
    return next - curr;
  }

  if (next === curr) {
    return 0;
  }

  return 7 - (curr + 1) + (next + 1);
};

type DayOfTheMonthValueType = (typeof dayOfTheMonthOptions)[number]['value'];
type TimeValueType = (typeof timeOptions)[number]['value'];

interface GetScheduleCronProps {
  frequency?: FrequencyOption;
  time: TimeValueType;
  dayOfTheMonth?: DayOfTheMonthValueType;
  dayOfTheWeek?: DayOfTheWeekEnum;
}

export const getScheduleCron = ({ frequency, dayOfTheWeek, dayOfTheMonth, time }: GetScheduleCronProps) => {
  const [hours, minutes] = time.split(',');
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth();

  const getMonthInfo = () => {
    if (frequency === FrequencyOption.WEEKLY) {
      const dayOfTheWeekIndex = Object.values(DayOfTheWeekEnum).findIndex((d) => d === dayOfTheWeek);
      const dayDiff = getDayDifference(date.getDay(), dayOfTheWeekIndex);
      const nextDay = new Date(date.setDate(date.getDate() + dayDiff));
      return { day: nextDay.getDate(), month: nextDay.getMonth() };
    }

    if (frequency === FrequencyOption.MONTHLY_LAST_DAY) {
      return { day: new Date(year, month + 1, 0).getDate() };
    }

    return { day: +(dayOfTheMonth || 0) };
  };

  const monthInfo = getMonthInfo();

  const newDate = new Date(year, monthInfo.month || month, monthInfo.day, +hours, +minutes);
  const newDateUTC = new Date(
    newDate.getUTCFullYear(),
    newDate.getUTCMonth(),
    newDate.getUTCDate(),
    newDate.getUTCHours(),
    newDate.getUTCMinutes(),
  );
  const dateUTC = newDateUTC.getDate();
  const dayUTC = newDateUTC.getDay();
  const hoursUTC = newDateUTC.getHours();
  const minutesUTC = newDateUTC.getMinutes();

  const dayOfTheWeekUTC = Object.values(DayOfTheWeekEnum)[dayUTC];

  switch (frequency) {
    case FrequencyOption.WEEKLY:
      return CronTime.onSpecificDaysAt([dayOfTheWeekUTC], hoursUTC, minutesUTC);

    case FrequencyOption.MONTHLY_LAST_DAY:
      return CronTime.everyMonthOn(dateUTC, hoursUTC, minutesUTC);

    case FrequencyOption.MONTHLY_SPECIFIC_DAY:
      return CronTime.everyMonthOn(dateUTC, hoursUTC, minutesUTC);
  }
};
