import React from 'react';

import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { DataCols } from 'src/components/admin-table/admin-table';
import Button from 'src/components/button/button';
import Icon from 'src/components/icon/icon';
import { RankListSchedules_rankListSchedules } from 'src/graphql-types/RankListSchedules';
import { useOrgLevel } from 'src/utils/auth';
import { parseCronValue } from 'src/utils/parse-cron-value/parse-cron-value';

import { getNextRankingPeriod, getSchedulePeriodFromEnum } from './usta-ranklist.utils';

interface ColumnsProps {
  handleEditSchedule: (id: string) => void;
}

export function useColumns({ handleEditSchedule }: ColumnsProps) {
  const { t } = useTranslation();
  const { isNational } = useOrgLevel();

  const columns: DataCols<RankListSchedules_rankListSchedules & { id: string }> = [
    {
      key: 'scheduled',
      title: ' ',
      getValue: () => <Icon name="sm-clock" spacing={{ margins: { sm: 'left' } }} />,
      noPadding: true,
      sort: false,
    },
    {
      key: 'nextRun',
      title: t('next run'),
      getValue: (m) => {
        return t('ranking schedule date', {
          date: dayjs(m.nextRunAt).local(),
        });
      },
    },
    {
      key: 'nextRankingPeriod',
      title: t('next ranking period'),
      getValue: (m) =>
        getNextRankingPeriod({
          start: m.data?.input?.dateRange?.start,
          end: m.data?.input?.dateRange?.end,
          nextRunAt: m.nextRunAt,
          t,
        }),
    },
    {
      key: 'runFrequency',
      title: t('run frequency'),
      getValue: (m) => parseCronValue({ value: m.data?.frequency, t, nextRunAt: m.nextRunAt }),
    },
    {
      key: 'scheduledRankingPeriod',
      title: t('scheduled ranking period'),
      getValue: (m) => getSchedulePeriodFromEnum({ period: m.data?.period, t }),
    },
    {
      key: 'edit schedule',
      title: ' ',
      getValue: (m) => (
        <Button hide={!isNational} level="tertiary" size="sm" onClick={() => handleEditSchedule(m.id)}>
          {t('edit schedule')}
        </Button>
      ),
    },
  ];

  return columns;
}
