import React, { useCallback, useMemo } from 'react';

import { Divider, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CustomGrid } from 'src/components/custom-grid/custom-grid';
import Panel from 'src/components/panel/panel';
import * as styles from 'src/components/rankings/ranking-run/ranking-run-header.module.less';
import ScheduleRankingRuns, { ScheduleCheckedEnum } from 'src/components/rankings/ranking-run/schedule-ranking-run';
import getListType from 'src/components/rankings/utils/getListType';
import useAgeGroupOptions, {
  GetAgeRestrictionLabelProps,
  GetDivisionTypeLabelProps,
} from 'src/components/rankings/utils/useAgeGroupOptions';
import { Body, H2 } from 'src/components/typography/typography';
import { ListTypeEnum } from 'src/graphql-types/globalRankingTypes';
import { useOrgLevel } from 'src/utils/auth';

import { RankingRunProps } from './ranking-runs';

interface RankingRunsHeaderProps {
  rankingRun: RankingRunProps;
  scheduleDisabled: boolean;
  setSchedule: (checked: boolean) => void;
  scheduleChecked: ScheduleCheckedEnum;
}

const RankingRunsHeader: React.FC<RankingRunsHeaderProps> = ({
  rankingRun,
  scheduleDisabled,
  setSchedule,
  scheduleChecked,
}) => {
  const { t } = useTranslation();
  const { isNational } = useOrgLevel();
  const { getAgeRestrictionLabel, getDivisionTypeLabel } = useAgeGroupOptions();

  const { rankListName, listType } = rankingRun;

  const displayScheduledRuns = useMemo(
    () => ![ListTypeEnum.L2_QUALIFIER].includes(listType as ListTypeEnum),
    [listType],
  );

  const handleSetScheduleChecked = useCallback(
    (checked: boolean) => {
      setSchedule(checked);
    },
    [setSchedule],
  );

  const ageRestrictionLabel = getAgeRestrictionLabel({ ...rankingRun, t } as GetAgeRestrictionLabelProps);
  const divisionTypeLabel = getDivisionTypeLabel({ ...rankingRun, t } as GetDivisionTypeLabelProps);

  return (
    <Panel extendedPadding>
      <Grid container item xs alignItems="center" justifyContent="space-between">
        <H2 spacing={{ margins: { sm: 'right' } }} noMargin>
          {rankListName}
        </H2>
        {isNational && displayScheduledRuns && (
          <ScheduleRankingRuns
            checked={scheduleChecked === ScheduleCheckedEnum.ON}
            setChecked={handleSetScheduleChecked}
            disabled={scheduleDisabled}
          />
        )}
      </Grid>
      <Divider className={styles.divider} />
      <Grid container>
        <CustomGrid spacing={{ margins: { lg: 'right' } }}>
          <Body size="md" bold>
            {t('player category')}
          </Body>
          <Body size="md">{rankingRun.playerType}</Body>
        </CustomGrid>

        <CustomGrid spacing={{ margins: { lg: 'horizontal' } }}>
          <Body size="md" bold>
            {t('age group')}
          </Body>
          <Body size="md">{ageRestrictionLabel}</Body>
        </CustomGrid>

        <CustomGrid spacing={{ margins: { lg: 'horizontal' } }}>
          <Body size="md" bold>
            {t('division type')}
          </Body>
          <Body size="md">{divisionTypeLabel}</Body>
        </CustomGrid>

        <CustomGrid spacing={{ margins: { lg: 'horizontal' } }}>
          <Body size="md" bold>
            {t('list type')}
          </Body>
          <Body size="md">{getListType(rankingRun.listType as ListTypeEnum, t)}</Body>
        </CustomGrid>
      </Grid>
    </Panel>
  );
};

export default RankingRunsHeader;
