import { ScheduleDetailsType } from 'src/components/rankings/ranking-runs/ranking-runs';
import { RankListSchedulePeriodEnum } from 'src/graphql-types/globalRankingTypes';

import getGenerateRankListsInput from './getGenerateRankListInput';
import { getMatchFormatOptions } from './getMatchFormatOption';
import { getScheduleCron } from './getScheduleCRONString';

interface DateRange {
  startDate: Date;
  endDate: Date;
}

const getDateRangeFromRankingPeriod = (rankingPeriod: RankListSchedulePeriodEnum): DateRange => {
  const currentDate = new Date();
  const oneMonthAgo = new Date(currentDate);
  const oneYearAgo = new Date(currentDate);

  switch (rankingPeriod) {
    case RankListSchedulePeriodEnum.ONE_MONTH:
      oneMonthAgo.setMonth(currentDate.getMonth() - 1);
      return {
        startDate: oneMonthAgo,
        endDate: new Date(currentDate.getTime() - 24 * 60 * 60 * 1000),
      };

    case RankListSchedulePeriodEnum.ONE_YEAR:
      oneYearAgo.setFullYear(currentDate.getFullYear() - 1);
      oneYearAgo.setHours(0, 0, 0, 0);
      return {
        startDate: new Date(oneYearAgo.getTime() + 24 * 60 * 60 * 1000),
        endDate: new Date(currentDate.getTime() - 24 * 60 * 60 * 1000),
      };

    default:
      throw new Error('Invalid ranking period');
  }
};

export const getScheduleInput = (scheduleFormDetails: ScheduleDetailsType, rankListMetadata) => {
  const { dayOfTheMonth, dayOfTheWeek, frequency, rankingPeriod, time } = scheduleFormDetails;
  const { startDate, endDate } = getDateRangeFromRankingPeriod(rankingPeriod?.value);
  const frequencyCronString = getScheduleCron({
    frequency: frequency?.value,
    dayOfTheWeek: dayOfTheWeek?.value,
    dayOfTheMonth: dayOfTheMonth?.value,
    time: time?.value,
  });

  Object.keys(rankListMetadata).forEach((key) => {
    if (rankListMetadata[key] === 'null') {
      delete rankListMetadata[key];
    }
  });

  const {
    ageRestriction,
    familyCategory,
    gender,
    listType,
    playerLevel,
    playerType,
    matchFormat,
    matchFormatType,
    region,
    divisionType,
    section,
    itemLimit,
    subDivision,
  } = rankListMetadata;

  const formatOptions = getMatchFormatOptions(matchFormat, matchFormatType, gender);

  const input = getGenerateRankListsInput({
    gender,
    matchFormat: formatOptions,
    listType,
    ageRestriction,
    playerType,
    divisionType,
    playerLevel,
    section,
    itemLimit,
    subDivision,
    familyCategory,
    region,
    startDate,
    endDate,
  });

  return { input, period: rankingPeriod?.value, frequency: frequencyCronString };
};
