import {
  td_MatchFormatEnum,
  td_MatchFormatTypeEnum,
  td_RankListGenderEnum,
} from 'src/graphql-types/TennisDataRankingTypes';

import { FormatOptionsEnum } from './getRankListFilters';

// Maps the API match format definition to the single component format definition
export const getMatchFormatOptions = (
  matchFormat: td_MatchFormatEnum,
  matchFormatType?: td_MatchFormatTypeEnum,
  gender?: td_RankListGenderEnum,
) => {
  if (matchFormat === td_MatchFormatEnum.SINGLES) {
    return FormatOptionsEnum.SINGLES;
  }
  if (
    matchFormat === td_MatchFormatEnum.DOUBLES &&
    (matchFormatType === td_MatchFormatTypeEnum.INDIVIDUAL || !matchFormatType)
  ) {
    if (gender === td_RankListGenderEnum.X) {
      return FormatOptionsEnum.MIXED_IND_DOUBLES;
    }
    return FormatOptionsEnum.INDIVIDUAL_DOUBLES;
  }
  if (matchFormat === td_MatchFormatEnum.DOUBLES && matchFormatType === td_MatchFormatTypeEnum.TEAM) {
    return FormatOptionsEnum.TEAM_DOUBLES;
  }
};
