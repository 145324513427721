import React from 'react';

import Layout from 'src/components/layout/layout';
import { PrivateRoute } from 'src/components/private-route/private-route';
import RankingRuns from 'src/components/rankings/ranking-runs/ranking-runs';
import SEO from 'src/components/seo';
import USTARanklist from 'src/components/usta-ranklist/usta-ranklist';
import { useV2Rankings } from 'src/utils/useV2Rankings';

const IndexPage: React.FC = () => {
  return (
    <Layout>
      <SEO title="Rankings group" />
      <PrivateRoute>{useV2Rankings() ? <RankingRuns /> : <USTARanklist />}</PrivateRoute>
    </Layout>
  );
};

export default IndexPage;
